.container {
  display: flex;
  flex-direction: column;
}

.loaderWrapper {
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  background: #f2f2f3;
  width: -webkit-fill-available;
  z-index: 1;
  opacity: 0.8;
}
