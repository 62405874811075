.headerContainer {
  height: 52px;
  background: white;
}
.headerContainerDesktop {
  height: 52px;
}

.logo {
  background-image: url('../public/assets/allo-purple-logo.png');
  width: 60px;
  height: 52px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.burgerMenu {
  background-image: url('/assets/hamburger.svg');
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 12px;
}

.profile {
  background-image: url('/assets/svgs/profile_purple.svg');
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 12px;
}
.profileCircle {
  background-image: url('/assets/svgs/profile_purple.svg');
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 12px;
}

.mockShadow {
  background: linear-gradient(
    180deg,
    rgba(9, 30, 66, 0.13) 0%,
    rgba(9, 30, 66, 0.13) 25%,
    rgba(9, 30, 66, 0.08) 25.01%,
    rgba(9, 30, 66, 0) 100%
  );
  height: 4px;
}

.OptionsOverlay {
  background: white;
  position: absolute;
  bottom: -92px;
  right: -24px;
  z-index: 9;
  width: 175px;
  height: 64px;
}
.logoutRow {
  height: 64px;
  padding: 0 24px;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px #091e421f;
}
.logoutRow:hover {
  height: 64px;
  padding: 0 24px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}
.logoutText {
  font-weight: 400;
  font-size: 16px;
  color: #222222;
}
